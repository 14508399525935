import axios from "axios";
import { sendRequestDotNet } from "../../../../../_utils/Axios";

export const CUSTOMERS_URL = "api/topupMyAccounts";
export const ACCOUNT_URL = "Account";
export const OFFER_URL = "Offer";


export function findMyTopupMyAccounts() {
  return sendRequestDotNet(`${ACCOUNT_URL}/TopUpMyAccount`, {"isWithAccountItem": true});
}
export function activateOffer(params) {
  return sendRequestDotNet(`${OFFER_URL}/Buy`, params);
}
export function giftOffer(params,headers) {
  return sendRequestDotNet(`${OFFER_URL}/Gift`, params,headers);
}
export function loadDataValidity() {
  return sendRequestDotNet(`${ACCOUNT_URL}/LoadData`, {});
}
export function addValidity(params) {
  return sendRequestDotNet(`${ACCOUNT_URL}/AddValidity`, params);
}

// UPDATE Status
export function updateStatusForTopupMyAccounts(ids, status) {
  return axios.post(`${CUSTOMERS_URL}/updateStatusForTopupMyAccounts`, {
    ids,
    status
  });
}
