import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register, validateLoginOtp } from "../_redux/authCrud";
import { KTCookie } from "../../../../_metronic/_assets/js/components/cookie";
import { EncryptOTP, sendRequestDotNet } from "../../../../_utils/Axios";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { PinInput } from "react-input-pin-code";
import { giftOffer } from "../../MyKorek/_redux/topupMyAccounts/topupMyAccountsCrud";
import { enqueueSnackbar } from "notistack";

// const ValidateOTPSchema = {
//   fullname: "",
// };

function ValidateOTP(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [showValidateOTP, setValidateOTP] = useState(undefined);
  const [pinValues, setPinValues] = useState(["", "", "", ""]);


  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const ValidateOTPSchema = Yup.object().shape({
    pincode: Yup.string(),
    // msisdn: Yup.string(),
  });

  const initialValuesPin = {
    // msisdn: "",
    pincode: ["", "", "", ""],
  };


  const formikValidateOTPService = useFormik({
    initialValuesPin,
    enableReinitialize: "true",
    validationSchema: ValidateOTPSchema,
    onSubmit: (values, { setStatus, setSubmitting, resetForm }) => {
      enableLoading();
      let headers = {
        otp: EncryptOTP(pinValues.join("")),
        idrequest: props.data,
      }
      setTimeout(() => {
        if (props.request == "Offer/Gift") {
          giftOffer(props.params, headers)
            .then((data) => {
              // setValidateOTP(false)
              if (data.error) {
                disableLoading();
                enqueueSnackbar(data.message, {
                  variant: "error"

                });
                setValidateOTP(false)
              } else {
                if (data.data.statusCode.code == 0) {
                  setValidateOTP(false)
                  enqueueSnackbar(data.data.statusCode.message, {
                    variant: "success",
                  });
                  disableLoading();
                }
                else if (data.data.statusCode.code == 111) {
                  // setValidateOTP(false);
                  enqueueSnackbar(data.data.statusCode.message, {
                    variant: "warning",
                  });
                }
                else {

                  enqueueSnackbar(data.data.statusCode.message, {
                    variant: "warning", autoHideDuration: 7000

                  });
                  setValidateOTP(false)
                  disableLoading();
                }
              }
              // setValidateOTP(false)
              disableLoading();
            })
            .catch((data) => {
              setValidateOTP(false)
            })
            .finally(() => {
              // setValidateOTP(false)
              disableLoading();
              setSubmitting(false);
            });
        } else if (props.request == "BlueProgram/GiftPoints") {
          sendRequestDotNet("BlueProgram/GiftPoints", props.params, headers).then((response) => {
            setLoading(false);
            if (response.data.statusCode.code == 0 || response.data.statusCode.code == 975) {
              enqueueSnackbar(response.data.statusCode.message, {
                variant: "success",
              });
              setValidateOTP(false);
            } else if (response.data.statusCode.code == 979) {
              // console.log(response)
              setValidateOTP(false);
              // setDataOTP(response.data.idrequest);
            }
            else if (response.data.statusCode.code == 111) {
              // setValidateOTP(false);
              enqueueSnackbar(response.data.statusCode.message, {
                variant: "warning",
              });
            }
            // formik.setFieldValue("destinationMsisdn","")
            // setMobileNumber("");
          });
        }
        else if (props.request == "Account/CreditTransfer") {

          sendRequestDotNet("Account/CreditTransfer", props.params, headers).then((response) => {
            setLoading(false);
            if (response.data.statusCode.code == 0 || response.data.statusCode.code == 975) {
              enqueueSnackbar(response.data.statusCode.message, {
                variant: "success",
              });
              setValidateOTP(false);
            } else if (response.data.statusCode.code == 979) {
              setValidateOTP(false);
              // setDataOTP(response.data.idrequest);
            }
            else if (response.data.statusCode.code == 111) {
              // setValidateOTP(false);
              enqueueSnackbar(response.data.statusCode.message, {
                variant: "warning",
              });
            }
            else {
              setValidateOTP(false);
              enqueueSnackbar(response.data.statusCode.message, {
                variant: "warning",
              });
            }
            // formik.setFieldValue("destinationMsisdn","")
            // setMobileNumber("");
          });
        }
      }, 1000);
    },
  });
  if (!props.showModal) return null;
  return (


    <Modal
      backdrop="static" show={showValidateOTP != undefined ? showValidateOTP : props.showModal} onHide={() => {
        // setScroll()
        setValidateOTP(false)
      }}>
      <Modal.Header
        className="justify-content-end"
        style={{ borderBottom: "0px" }}
        closeButton
      ></Modal.Header>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        // onSubmit={formikPinCode.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        <Modal.Body>
          <div className="text-center modalHeader mb-10 mb-lg-20">
            <h3 className="font-size-h3">
              <b>
                <FormattedMessage id="AUTH.VERIFY.OTP" />
              </b>
            </h3>
            {/* <p className="font-weight-bold">
              <FormattedMessage id="AUTH.VERIFY_NBR" />
            </p> */}
          </div>
          {/* <p className="text-center">
          {intl.formatMessage({ id: "AUTH.PLEASE_ENTER_VER" }) +
            "“" +
            msisdnVal +
            "”"}
        </p> */}
          {/* end::Head */}

          {/*begin::Form*/}
          {formikValidateOTPService.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formikValidateOTPService.status}
              </div>
            </div>
          )}

          <div className="form-group fv-plugins-icon-container">
            <PinInput
              placeholder="."
              values={pinValues}
              size="lg"
              onChange={(value, index, values) => {
                // console.log(value, index, values);
                // console.log(pinValues);
                formikValidateOTPService.setValues("pincode", pinValues);
                // formikPinCode.setFieldValue("pincode",values);
                setPinValues(values);
              }}
            />
            {/* {formikPinCode.touched.pincode && formikPinCode.errors.pincode ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formikPinCode.errors.pincode}</div>
            </div>
          ) : null} */}
          </div>
        </Modal.Body>

        <Modal.Footer
          style={{ borderTop: "0px" }}
          className="justify-content-center"
        >
          <Row
            className=""
            style={{
              justifyContent: "center",
            }}
          >

            <Col xl={12} className="text-center mt-5">
              <Button
                className="mx-auto text-center"
                // type="submit"
                onClick={() => formikValidateOTPService.handleSubmit()}
                id="kt_login_signin_submit"
                variant="warning"
                disabled={
                  loading || pinValues.join("").length < 4
                }
              >
                <b>
                  <FormattedMessage id="AUTH.VERIFY"></FormattedMessage>
                </b>
                {loading && (
                  <span className="mx-3 spinner spinner-white spinner-center"></span>
                )}
              </Button>
            </Col>
            {/* <Button variant="secondary" onClick={() => setShow(false)}>
          <b>Close</b>
        </Button> */}
          </Row>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

export default injectIntl(connect(null, auth.actions)(ValidateOTP));
