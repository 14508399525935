/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { BrowserRouter as Router, Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { SnackbarProvider } from "notistack";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import WebPage from "./WebPage";
import BusinessPage from "./BusinessPage";
import { LayoutBusiness } from "../_metronic/layout/components/LayoutBusiness";
import ScrollToTop from "../ScrollToTop";
// import { IndexPage } from "./modules/Web/pages/IndexPage";

export function Routes() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null
    }),
    shallowEqual
  );

  return (
    <Router>
      <Switch>


        {/* <Route path="/error" component={ErrorsPage} />*/}
        <Route path="/logout" component={Logout} />

        {!isAuthorized && <Redirect from="/my-korek" to="/home" />}
        {!isAuthorized ? (
          <Layout>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              autoHideDuration={7000}
            />
            <ScrollToTop />
            <WebPage />
          </Layout>
          /*Redirect to `/auth` when user is not authorized*/
          // <Redirect to="/auth/login" />
          // <Layout>
          //   <WebPage />
          // </Layout>
        ) : (
          <Layout>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              autoHideDuration={7000}
            />
            <ScrollToTop />
            <BasePage />
            {/* <WebPage /> */}
          </Layout>
        )}
      </Switch>
    </Router>
  );
}
