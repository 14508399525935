/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import FormWrapper from "./components/FormWrapper";
import FormikWizard from "formik-wizard";
import { getNumberLoadData, getServices } from "../../../UserProfile/_redux/Profile/profileActions";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { object, string } from "yup";
import { Address } from "./components/Address/Address";
import { BasicInfo } from "./components/BasicInfo/BasicInfo";
import { CompanyInfo } from "./components/CompanyInfo/CompanyInfo";
import { phoneRegExp, phoneRegExp1, phoneRegExp2 } from "../../../../../_utils/Axios";


const MyNumberMyChoice = () => {
  const intl = useIntl()

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleSubmit = React.useCallback(values => {
    console.log("full values:", values);
  }, []);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getServices());
    dispatch(getNumberLoadData())
  }, []);
  return (
    <Container className="" fluid>
      <div className="my-number-my-choice">
        <Row className="bg-slider pb-0">
          <Col className="padding-container" style={{ alignContent: "center" }} xl={7} sm={12} xs={12}>
            <h1 className="title">{intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.TITLE" })}</h1>
            {/* <p className="sub-title py-3">
              {intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.DESC" })}
            </p> */}
          </Col>
          <Col className="slide-offers px-0" xl={5} sm={12} xs={12}></Col>
        </Row>
        <div className="padding-container">

          <FormikWizard
            steps={[
              {
                id: "basic",
                component: BasicInfo,
                initialValues: {
                  msisdn: "",
                },
                validationSchema: object().shape({
                  msisdn: string().required(intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.MSISDN.REQ" })),
                }),
                actionLabel: intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SUBMIT" })
              },
              {
                id: "address",
                component: Address,
                initialValues: {
                  customerName: "",
                  idNumber: "",
                  contactNumber: "",
                  idGovernorate: "",
                  idLocation: ""
                },
                validationSchema: object().shape({
                  customerName: string().trim().matches(
                    /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
                    'Name can only contain letters.'
                  ).required(intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.CUST.REQ" })),
                  idNumber: string().trim().required(intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.ID_NBR.REQ" })),
                  contactNumber: string()
                    // .trim()
                    // .test(
                    //   'matches-both',
                    //   intl.formatMessage({ id: "AUTH.VALIDATION.INVALID_PHONE" }),
                    //   (value) => {
                    //     console.log(value)
                    //     console.log(phoneRegExp.test(value))
                    //     console.log(phoneRegExp1.test(value))

                    //     phoneRegExp.test(value) || phoneRegExp1.test(value)
                    //   }
                    // )
                    .min(
                      11,
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_PHONE_LENGTH",
                      })
                    )
                    .matches(
                      phoneRegExp1,
                      intl.formatMessage({
                        id: "AUTH.VALIDATION.INVALID_PHONE",
                      })
                    )
                    // .max(
                    //   13,
                    //   intl.formatMessage({
                    //     id: "AUTH.VALIDATION.TEL.MAX_LENGTH_FIELD",
                    //   })
                    // )
                    .required(intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.CONTACT_NBR.REQ" })),
                  idLocation: string().required(intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.LOCATION.REQ" })),
                  idGovernorate: string().required(intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.GOV.REQ" }))
                }),
                actionLabel: intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.CONTINUE" })
              },
              {
                id: "company",
                component: CompanyInfo,
                initialValues: {
                },
                validationSchema: object().shape({
                }),
                actionLabel: intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.CONTINUE" })
              }]}
            onSubmit={handleSubmit}
            render={FormWrapper}
          />
        </div>
      </div>
    </Container>
  );
};
export default MyNumberMyChoice;
