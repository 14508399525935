import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { FastField, Field, useFormikContext } from "formik";
import { shallowEqual, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useFormikWizard } from "formik-wizard";
import { Select } from "../../../../../../../_metronic/_partials/controls";

export function Address() {
  const { errors, touched, setFieldValue, values } = useFormikContext();
  const intl = useIntl();
  const { governorates } = useSelector((state) => state.profileSlice, shallowEqual);
  const context = useFormikWizard();

  const [selectedLocation, setSelectedLocation] = useState("");

  useEffect(() => {
    const element = document.getElementById("top");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    let cbox = document.querySelectorAll('.tel-input');
    for (let i = 0; i < cbox.length; i++) {
      cbox[i].addEventListener('keydown', function (evt) {
        !/(^\d*\.?\d*$)|(Backspace|Control|Meta|a)/.test(evt.key) && evt.preventDefault();
      });
    }
  }, []);

  useEffect(() => {
    if (context) {
      console.log(context);
    }
  }, [context]);

  const handleLocationChange = (event) => {
    const locationId = event.target.value;
    setSelectedLocation(locationId);
    setFieldValue("idGovernorate", locationId); // Update Formik value
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.CUST_INFO" /></Card.Title>
          <div className="form-group row mb-0">
            <div className="col-lg-6">
              <label className="mt-2">
                <b><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.CUST_NAME" />{" "}<span className="text-danger">*</span></b>
              </label>
              <FastField
                className="form-control" name="customerName" id="customerName"
                placeholder={intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.ENTER_NAME" })} />
              {touched.customerName && errors.customerName ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.customerName}</div>
                </div>
              ) : null}
            </div>
            <div className="col-lg-6">
              <label className="mt-2">
                <b><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.CONTACT_NUMBER" />{" "}<span className="text-danger">*</span></b>
              </label>
              <FastField
                type="text"
                maxLength={11}  // Add this line to limit the input
                className="form-control tel-input" name="contactNumber" id="contactNumber"
                // placeholder={intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.CONTACT_NUMBER" })} 
                placeholder="07xxxxxxxxx"
                />
              {touched.contactNumber && errors.contactNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.contactNumber}</div>
                </div>
              ) : null}
            </div>
            <div className="col-lg-6">
              <label className="mt-2">
                <b><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.ID_NUMBER" />{" "}<span className="text-danger">*</span></b>
              </label>
              <FastField
                type="number"
                className="form-control tel-input"
                name="idNumber"
                id="idNumber"
                placeholder={intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.ENTER_ID_NUMBER" })}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 15) {
                    setFieldValue("idNumber", value);
                  }
                }}
              />

              {touched.idNumber && errors.idNumber ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{errors.idNumber}</div>
                </div>
              ) : null}
            </div>
            <div className="col-lg-6">
              <label className="mt-2">
                <b><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.GOV" />{" "}<span className="text-danger">*</span></b>
              </label>
              <Field name="idGovernorate">
                {({ field, form }) => (
                  <div>
                    <Select
                      className="form-control"
                      name="idGovernorate"
                      value={field.value}
                      onChange={handleLocationChange}
                      placeholder={intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SELECT_GOV" })}
                    >
                      <option value="">
                        {intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SELECT_GOV" })}
                      </option>
                      {governorates &&
                        governorates.map((x) => (
                          <option key={x.idGovernorate} value={x.idGovernorate}>
                            {x.governorateName}
                          </option>
                        ))}
                    </Select>
                    {form.touched.idGovernorate && form.errors.idGovernorate ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{form.errors.idGovernorate}</div>
                      </div>
                    ) : null}
                  </div>
                )}
              </Field>
            </div>
            <div className="col-lg-6">
              <label className="mt-2">
                <b><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.LOCATION" />{" "}<span className="text-danger">*</span></b>
              </label>
              <Field name="idLocation">
                {({ field, form }) => (
                  <div>
                    <Select
                      className="form-control"
                      name="idLocation"
                      value={field.value}
                      placeholder={intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SELECT_LOCATION" })}
                      onChange={(e) => setFieldValue("idLocation", e.target.value)}
                    >
                      <option value="">
                        {intl.formatMessage({ id: "KOREK_SERVICES.MY_NBR_MY_CHOICE.SELECT_LOCATION" })}
                      </option>
                      {selectedLocation &&
                        governorates
                          .find((loc) => loc.idGovernorate === parseInt(selectedLocation))
                          ?.locations.map((subLoc) => (
                            <option key={subLoc.idParam} value={subLoc.idParam}>
                              {subLoc.value}
                            </option>
                          ))}
                    </Select>
                    {form.touched.idLocation && form.errors.idLocation ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">{form.errors.idLocation}</div>
                      </div>
                    ) : null}
                  </div>
                )}
              </Field>
            </div>
            <div className="col-lg-12 pt-5">
              <Button className="btn btn-warning" type="submit">
                <b><FormattedMessage id="KOREK_SERVICES.MY_NBR_MY_CHOICE.SUBMIT" /></b>
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
