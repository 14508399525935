import axios from "axios";
import { sendRequestDotNet } from "../../../../_utils/Axios";
import { KTCookie } from "../../../../_metronic/_assets/js/components/cookie";
import { useDispatch } from "react-redux";
import { getMainLoadData, getOffers, getOffersLoadData, getServices, initialization } from "../../UserProfile/_redux/Profile/profileActions";

export const LOGIN_URL = "Profile/Login";
export const LOGIN_URL_OTP = "Profile/LoginByOtp";
export const COMPLETE_PROF_URL = "Profile/Add";
export const GET_PROFILE_URL = "Profile/Get";
export const RESET_PASS_PINCODE_URL = "Profile/ResetPasswordPincode";
export const DEACTIVATE = "Account/Deactivate";
export const SIGN_UP_URL = "Registration/SignUp";
export const RESEND_CODE_URL = "Registration/ResendPincode";
export const FORGET_PASSWORD_RESEND_PINCODE_URL = "Registration/ForgetPasswordResendPincode";
export const FORGET_PASSWORD_PINCODE_URL = "Registration/ForgetPasswordPincode";
export const FORGET_PASSWORD_PINCODE_VERIFICATION_URL = "Registration/ForgetPasswordPincodeVerification";
export const PINCODE_VER_URL = "Registration/PincodeVerification";
export const VALIDATE_OTP_URL = "Profile/ValidateLoginOtp";
export const VALIDATE_OTP_PASS = "Profile/ValidateLoginPass";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;


let headers = {
  idusertype:"3"
};

export function login(username, password) {
  return sendRequestDotNet(LOGIN_URL, { username, password },headers);
}
export function loginOTP(username) {
  return sendRequestDotNet(LOGIN_URL_OTP, { username },headers);
}
export function deactivate() {
  return sendRequestDotNet(DEACTIVATE, {});
}

export function signUp(msisdn) {
  return sendRequestDotNet(SIGN_UP_URL, { msisdn },headers);
}
export function resendCode(msisdn) {
  return sendRequestDotNet(RESEND_CODE_URL, { msisdn },headers);
}
export function resendCodeForgetPass(msisdn) {
  return sendRequestDotNet(FORGET_PASSWORD_RESEND_PINCODE_URL, { msisdn },headers);
}
export function pinCodeVerification(pincode) {
  return sendRequestDotNet(PINCODE_VER_URL, pincode,headers);
}
export function validateLoginOtp(pincode,headersotp) {
  // let headersotp = [...headers,headersotp]
  console.log(headersotp)
  console.log(pincode)
  return sendRequestDotNet(VALIDATE_OTP_URL, pincode,headersotp);
}
export function validateLoginPass(pincode,headersotp) {
  // let headersotp = [...headers,headersotp]
  console.log(headersotp)
  console.log(pincode)
  return sendRequestDotNet(VALIDATE_OTP_PASS, pincode,headersotp);
}
export function completeProfile(profile) {
  return sendRequestDotNet(COMPLETE_PROF_URL, profile,headers);
}

export function forgetPasswordPincode(msisdn) {
  return sendRequestDotNet(FORGET_PASSWORD_PINCODE_URL, { msisdn },headers);
}

export function forgetPasswordPincodeVerification(pincode) {
  return sendRequestDotNet(FORGET_PASSWORD_PINCODE_VERIFICATION_URL, pincode,headers);
}

export function resetPasswordPincode(password) {
  return sendRequestDotNet(RESET_PASS_PINCODE_URL, password,headers);
}
export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  KTCookie.setCookie("idUserType","2")
  return sendRequestDotNet(GET_PROFILE_URL, {"isWithAccountItem":true});
}

        // const dispatch = useDispatch();
// export function logout() {

//         return sendRequestDotNet("Profile/Logout", {}).then((response) => {
//           response.data.statusCode.code==0 && sendRequestDotNet("Registration/SkipLogin ", {}, headers).then(
//             (response) => {
//               KTCookie.setCookie("isSkipLogin", true);
//               KTCookie.setCookie("idUserType", "1");
//               KTCookie.setCookie("token", response.data.idGuest);
//               KTCookie.setCookie("idGuest", response.data.idGuest);
//               // dispatch(initialization());
//               // dispatch(getMainLoadData());
//               // dispatch(getServices());
//               // dispatch(getOffersLoadData());
//               // dispatch(
//               //   getOffers({
//               //     idCategory: 0,
//               //     idPlan: 0,
//               //     isGetBestOffers: false,
//               //     page: 0,
//               //     pageSize: 100,
//               //   })
//               // );
//             }
//           );
//         });
// }
