import axios from "axios";
import {sendRequestDotNet} from "../../../../../_utils/Axios"

export const PROFILE_URL = "Profile";
export const FILE_URL = "File";
export const OFFER_URL = "Offer";
export const MAIN_URL = "Main";
export const SECTION_URL = "Section";
export const NUMBER_URL = "Number";
export const ACCOUNT_URL = "Account";
export const CONTROL_URL = "Control";
export const BUSINESS_URL = "Business";
export const BLUE_PROGRAM_URL = "BlueProgram";

export function getProfile() {
  return sendRequestDotNet(`${PROFILE_URL}/Get`,{"isWithAccountItem":true},{})
}
export function getSectionById(queryParams) {
  return sendRequestDotNet(`${SECTION_URL}/SectionContent`,queryParams,{})
}
export function getGovernorates(queryParams) {
  return sendRequestDotNet(`${SECTION_URL}/List`,queryParams,{})
}
export function getSectionLoaddata() {
  return sendRequestDotNet(`${SECTION_URL}/LoadData`,{},{})
}
export function initialization() {
  return sendRequestDotNet(`${MAIN_URL}/Initialization`,{},{})
}
export function getNewsById(queryParams) {
  return sendRequestDotNet(`${MAIN_URL}/News/ById`,queryParams,{})
}
export function initializationBlue() {
  return sendRequestDotNet(`${BLUE_PROGRAM_URL}/Init`,{},{})
}

export function saveProfile(queryParams) {
  return sendRequestDotNet(`${PROFILE_URL}/Update`,queryParams,{})
}
export function uploadProfilePicture(queryParams) {
  return sendRequestDotNet(`${FILE_URL}/UploadProfilePicture`,queryParams,{})
}
export function offersList(queryParams) {
  return sendRequestDotNet(`${OFFER_URL}/List`,queryParams,{})
}
export function getOffersLoadData() {
  return sendRequestDotNet(`${OFFER_URL}/LoadData`,{},{})
}
export function getVasOffers() {
  return sendRequestDotNet(`${OFFER_URL}/VasOffers/List`,{},{})
}
export function getMainLoadData() {
  return sendRequestDotNet(`${MAIN_URL}/LoadData`,{},{})
}
export function getNumberLoadData() {
  return sendRequestDotNet(`${NUMBER_URL}/LoadData`,{},{})
}
export function getFAQData(queryParams) {
  return sendRequestDotNet(`${MAIN_URL}/GetFaqData`,queryParams,{})
}
export function getServices() {
  return sendRequestDotNet(`${MAIN_URL}/GetServices`,{},{})
}
export function connectedDevices() {
  return sendRequestDotNet(`${ACCOUNT_URL}/ConnectedDevices`,{},{})
}
export function blockNumber(queryParams) {
  return sendRequestDotNet(`${CONTROL_URL}/Block`,queryParams,{})
}
export function getBusinessLoadData() {
  return sendRequestDotNet(`${BUSINESS_URL}/LoadData`,{},{})
}
export function getBusinessContent() {
  return sendRequestDotNet(`${BUSINESS_URL}/GetContent `,{},{})
}