import { enqueueSnackbar } from "notistack";
import * as requestFromServer from "./profileCrud";
import { profileSlice, callTypes } from "./profileSlice";

const { actions } = profileSlice;

export const initialization = (queryParams) => (dispatch) => {
  // console.log("actions");
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .initialization(queryParams)
    .then((response) => {
      const { splittedBanners,services,middleBanners,bundles,news,faqSections,shortcuts,section } = response.data;
      dispatch(actions.initialized({ splittedBanners,services,middleBanners,bundles,news,faqSections,shortcuts,section }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getNewsById = (queryParams) => (dispatch) => {
  // console.log("actions");
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNewsById(queryParams)
    .then((response) => {
      const { news } = response.data;
      dispatch(actions.newsByIdInitialized({ news }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const initializationBlue = (queryParams) => (dispatch) => {
  // console.log("actions");
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .initializationBlue(queryParams)
    .then((response) => {
      const { splittedBanners,quickAccess,faqList,features } = response.data;
      dispatch(actions.initializedBlue({ splittedBanners,quickAccess,faqList,features }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getSectionById = (queryParams) => (dispatch) => {
  // console.log("actions");
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getSectionById(queryParams)
    .then((response) => {
      const { list } = response.data;
      dispatch(actions.getSectionByIdLoaded({ list }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getGovernorates = (queryParams) => (dispatch) => {
  // console.log("actions");
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getGovernorates(queryParams)
    .then((response) => {
      const { governorates,stores } = response.data;
      dispatch(actions.getGovernoratesIdLoaded({ governorates,stores }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getSectionLoaddata = (queryParams) => (dispatch) => {
  // console.log("actions");
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getSectionLoaddata(queryParams)
    .then((response) => {
      const { list } = response.data;
      dispatch(actions.getSectionLoadedData({  }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getProfile = (queryParams) => (dispatch) => {
  // console.log("actions");
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getProfile(queryParams)
    .then((response) => {
      const { statusCode, profile,userProfile ,accountItems} = response.data;
      dispatch(actions.profileFetched({ statusCode, profile,userProfile,accountItems }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const updateProfile = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .saveProfile(queryParams)
    .then((response) => {
      const { statusCode } = response.data;
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
      dispatch(actions.profileSaved({ statusCode }));
      return statusCode;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const uploadProfilePicture = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .uploadProfilePicture(queryParams)
    .then((response) => {
      const { statusCode } = response.data;
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(statusCode.message, { 
        variant:  "warning"
      });
      dispatch(actions.profileSaved({ statusCode }));
      return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getOffers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .offersList(queryParams)
    .then((response) => {
      const { offers ,section} = response.data;
      dispatch(actions.offersUpdated({ offers,section }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getOffersLoadData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getOffersLoadData(queryParams)
    .then((response) => {
      const { offerCategories, offerPlans } = response.data;
      dispatch(actions.offersLoadedData({ offerCategories, offerPlans }));
      return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getServices = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getServices(queryParams)
    .then((response) => {
      const { services,section } = response.data;
      dispatch(actions.servicesLoaded({ services,section }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getVasOffers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getVasOffers(queryParams)
    .then((response) => {
      const { list,section } = response.data;
      dispatch(actions.vasOffersLoaded({ list,section }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getMainLoadData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getMainLoadData(queryParams)
    .then((response) => {
      const { navbarItems, showBills,faqCategories,socialMedia,appLinks,offerCategories,offerPlans } = response.data;
      dispatch(actions.mainLoadedData({ navbarItems, showBills,faqCategories,socialMedia,appLinks,offerCategories,offerPlans }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getNumberLoadData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getNumberLoadData(queryParams)
    .then((response) => {
      const { prefixes,positions,governorates,priceRanges,minMaxPrice } = response.data;
      dispatch(actions.numberLoadedData({ prefixes,positions,governorates,priceRanges,minMaxPrice }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getFAQData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getFAQData(queryParams)
    .then((response) => {
      const { list } = response.data;
      dispatch(actions.FAQDataLoaded({ list }));
      return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const connectedDevices = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .connectedDevices(queryParams)
    .then((response) => {
      const { list } = response.data;
      dispatch(actions.connectedDevices({ list }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const blockNumber = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .blockNumber(queryParams)
    .then((response) => {
      const { description,responseCode,statusCode } = response.data;
      if (statusCode.code == 0) enqueueSnackbar(statusCode.message,{ 
        variant:  "success"
      });
      else enqueueSnackbar(description, { 
        variant:  "warning"
      });
      dispatch(actions.blockedNumber({ description,responseCode,statusCode }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getBusinessLoadData = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getBusinessLoadData()
    .then((response) => {
      const { navbarItems,news,services,splittedBanners } = response.data;
      dispatch(actions.businessLoadedData({ navbarItems,news,services,splittedBanners }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
export const getBusinessContent = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return getBusinessContent
    .getBusinessLoadData(queryParams)
    .then((response) => {
      const { offerCategories, offerPlans } = response.data;
      dispatch(actions.businessContentLoaded({ offerCategories, offerPlans }));
      // return response.data;
    })
    .catch((error) => {
      error.clientMessage = "Can't find profile";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const setLogin = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return dispatch(actions.settingLogin({ login:queryParams }));
};