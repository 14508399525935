import React, { useEffect, useState, useMemo } from "react";
import clsx from "clsx";
import { Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { SearchResult } from "./SearchResult";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../../_partials/dropdowns";
import { sendRequestDotNet } from "../../../../../../_utils/Axios";
import { FormattedMessage, useIntl, injectIntl } from "react-intl";
const fakeData = [
  {
    type: 0,
    text: "Reports",
  },
  {
    type: 1,
    text: "AirPlus Requirements",
    description: "by Grog John",
    iconImage: toAbsoluteUrl("/media/files/doc.svg"),
  },
  {
    type: 1,
    text: "TechNav Documentation",
    description: "by Mary Brown",
    iconImage: toAbsoluteUrl("/media/files/pdf.svg"),
  },
  {
    type: 1,
    text: "All Framework Docs",
    description: "by Grog John",
    iconImage: toAbsoluteUrl("/media/files/zip.svg"),
  },
  {
    type: 1,
    text: "AirPlus Requirements",
    description: "by Tim Hardy",
    iconImage: toAbsoluteUrl("/media/files/xml.svg"),
  },
  {
    text: "Customers",
    type: 0,
  },
  {
    type: 1,
    text: "Jimmy Curry",
    description: "Software Developer",
    iconImage: toAbsoluteUrl("/media/users/300_11.jpg"),
  },
  {
    type: 1,
    text: "Milena Gibson",
    description: "UI Designer",
    iconImage: toAbsoluteUrl("/media/users/300_16.jpg"),
  },
  {
    type: 1,
    text: "Stefan JohnStefan",
    description: "Marketing Manager",
    iconImage: toAbsoluteUrl("/media/users/300_22.jpg"),
  },
  {
    type: 1,
    text: "Anna Strong",
    description: "Software Developer",
    iconImage: toAbsoluteUrl("/media/users/300_5.jpg"),
  },
  {
    type: 0,
    text: "Files",
  },
  {
    type: 1,
    text: "2 New items submitted",
    description: "Marketing Manager",
    iconClassName: "flaticon2-box font-danger",
  },
  {
    type: 1,
    text: "79 PSD files generated",
    description: "by Grog John",
    iconClassName: "flaticon-psd font-brand",
  },
  {
    type: 1,
    text: "$2900 worth products sold",
    description: "Total 234 items",
    iconClassName: "flaticon2-supermarket font-warning",
  },
  {
    type: 1,
    text: "4 New items submitted",
    description: "Marketing Manager",
    iconClassName: "flaticon-safe-shield-protection font-info",
  },
];

export function SearchDropdown() {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [searchValue, setSearchValue] = useState("");
  let timeoutId;
  const intl = useIntl();

  const clearTimeout = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
      timeoutId = undefined;
    }
  };

  const handleSearchChange = (event) => {

    // document.body.style.overflow = 'hidden'
    setData();
    setSearchValue(event.target.value);

    // if (event.target.value.length > 2) {
    //   clearTimeout();

    //   setLoading(true);
    //   let params = {
    //     keyword: event.target.value
    //   }
    //   setTimeout(() => {
    //   sendRequestDotNet("Search/Search", params, {}).then((response) => {
    //     // !response.error && setContent(response.data)
    //     // timeoutId = 
    //     !response.error && setData(response.data.list);
    //     !response.error && response.data.list && setLoading(false);
    //     response.error && setLoading(false)
        
    //   })// 
    //   }, 1000);
    //   // simulate getting search result

    // }
  };
  const handleSearchBtn = (event) => {

    // document.body.style.overflow = 'hidden'
    setData();

      clearTimeout();

      setLoading(true);
      let params = {
        keyword: searchValue
      }
      sendRequestDotNet("Search/Search", params, {}).then((response) => {
        // !response.error && setContent(response.data)
        // timeoutId = 
        !response.error && setData(response.data.list);
        !response.error && response.data.list && setLoading(false);
        response.error && setLoading(false)
        
      })

    }


  const clear = () => {
    // document.body.style.overflow = 'auto'
    setData();
    setSearchValue("");
  };

  useEffect(() => {
    return () => {
      clearTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.search.layout") ===
        "offcanvas",
    };
  }, [uiService]);

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <div
            className="btn btn-icon btn-clean btn-lg mr-1"
            id="kt_quick_search_toggle"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
            </span>
          </div>
        </div>
      )}
      {!layoutProps.offcanvas && (
        <Dropdown
          alignRight
          drop="down"
          onToggle={(nextShow,meta) => {
            setData();
            setLoading(false);
            setSearchValue("");
            // console.log(meta)
            // console.log(nextShow)
            // console.log(document.body.style.overflow)
            // console.log(document.getElementById("layout-id").style.filter)
            document.body.style.overflow == 'hidden' ? document.body.style.overflow = 'auto' : document.body.style.overflow = 'hidden'
            // document.getElementById("layout-id").style.filter = "blur(50px)" ?
            //   document.getElementById("layout-id").style.filter = "none" :
            nextShow == true ?document.getElementById("layout-id").style.filter = "blur(50px)":document.getElementById("layout-id").style.filter = "none"
          }}
          id="kt_quick_search_toggle"
        >
          <Dropdown.Toggle as={DropdownTopbarItemToggler}>
            <OverlayTrigger
              placement="bottom"
              overlay={<></>
                // <Tooltip id="search-panel-tooltip">Quick search</Tooltip>
              }
            >
              <div className="btn btn-icon btn-clean btn-lg btn-dropdown mr-1">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                  />
                </span>
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ position: "fixed", width: "100%" }} className="dropdown-menu p-0 m-0 dropdown-menu-left dropdown-menu-anim-up dropdown-menu-xl search-cust">
            <div
              id="kt_quick_search_dropdown"
              className={clsx("quick-search quick-search-dropdown", {
                "quick-search-has-result": data && data.length,
              })}
            >
              <form className="quick-search-form">
                <div className="input-group">
                  <div className={`input-group-prepend`}>
                    <span className="input-group-text">
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Search.svg"
                          )}
                        />
                      </span>
                    </span>
                  </div>
                  <input
                    type="text"
                    autoFocus={true}
                    placeholder={
                      intl.formatMessage({
                        id: "top.MyKorek",
                      })+"..."}
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="form-control"
                  />
                  <Button disabled={loading || searchValue.length == 0} className="btn btn-primary mr-3" onClick={handleSearchBtn}>{intl.formatMessage({id:"top.MyKorek"})}</Button>

                  <div
                    className={`input-group-append ${loading ? "spinner spinner-sm spinner-primary" : ""
                      }")}`}
                  >
                    <span className="input-group-text">
                      <i
                        style={{
                          display:
                            loading && searchValue && searchValue.length > 0
                              ? "none"
                              : "flex",
                        }}
                        onClick={clear}
                        className="quick-search-close ki ki-close icon-sm text-muted"
                      />
                    </span>
                  </div>
                </div>
              </form>
              {data && <SearchResult data={data} />}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}
